import {
	Carousel
} from "bootstrap"

$(document).on('turbolinks:load', function () {
	let project1Descriptions = $('#category-1-projects')
	let project2Descriptions = $('#category-2-projects')
	let project3Descriptions = $('#category-3-projects')
	let carousel = $('#projectsCarouselControls')
	let modals = document.getElementsByClassName('modal')
	if (carousel.length) {
		var carouselObject = new Carousel(carousel, {
			ride: 'carousel',
			interval: 3000,
			slide: true
		})
		carousel.on('slide.bs.carousel', function onSlide(ev) {
			let id = ev.relatedTarget.dataset.index;
			switch (id) {
				case "1":
					project3Descriptions.toggleClass('active-project')
					project1Descriptions.toggleClass('active-project')
					break;
				case "2":
					project1Descriptions.toggleClass('active-project')
					project2Descriptions.toggleClass('active-project')
					break;
				case "3":
					project2Descriptions.toggleClass('active-project')
					project3Descriptions.toggleClass('active-project')
					break;
			}
		})

		if (modals.length > 0) {
			Array.from(modals).forEach(modal => {
				modal.addEventListener('show.bs.modal', function (event) {
					carouselObject.pause()
				})
				modal.addEventListener('hide.bs.modal', function (event) {
					carouselObject.cycle()
				})
			});
		}

		var mediaQuery = window.matchMedia('(max-width:768px)');

		function toggleCarouselBasedOnScreenSize(mediaQuery) {
			if (mediaQuery.matches) {
				carouselObject.pause();
			} else {
				carouselObject.cycle();
			}
		}
		window.addEventListener('resize', () => {
			toggleCarouselBasedOnScreenSize(mediaQuery)
		})

		toggleCarouselBasedOnScreenSize(mediaQuery)
	}
});
