import {
  Carousel
} from "bootstrap"


document.addEventListener('turbolinks:load', () => {
  let processCarouselHTMLElement = document.getElementById('process-carousel')
  if (processCarouselHTMLElement) {
    let processCarousel = new Carousel(processCarouselHTMLElement, {
      ride: 'carousel',
      interval: 5000,
      slide: true,
    })
  }
  let homeCarouselHTMLElement = document.getElementById('home-carousel')
  if (homeCarouselHTMLElement) {
    let homeCarousel = new Carousel(homeCarouselHTMLElement, {
      ride: 'carousel',
      interval: 3000
    })
    homeCarousel.cycle()
  }
  let hqCarouselHTMLElement = document.getElementById('hqCarousel')
  if (hqCarouselHTMLElement) {
    let hqCarousel = new Carousel(hqCarouselHTMLElement, {
      ride: 'carousel',
      interval: 4000
    })
    hqCarousel.cycle()
  }
})
